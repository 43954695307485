import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { useLocalizedContent } from '../../utils/localization/useLocalizedContent'

const FormContainer = ({
  title,
  subTitle,
  Component,
  location,
  showCreateAccount = true
}) => {
  const data = useStaticQuery(query)
  const { translations } = useLocalizedContent(data)

  return (
    <div className="">
      <div className="w-full max-w-xl py-6 mb-6 text-center md:px-12 md:py-8">
        <h1 className="text-3xl leading-snug">{title}</h1>
        {showCreateAccount && (
          <p className="mt-4 leading-tight text-md">
            {translations[translationsKeys.notMemberYet] || "Not a member yet?"}&nbsp;
            <Link to="/signup">{translations[translationsKeys.createAccount] || "Create an account"}</Link>
          </p>
        )}
        {subTitle && (
          <div className="pt-4 text-sm leading-relaxed text-gp-grey-80">
            {subTitle}
          </div>
        )}
        <div>
          <Component location={location} />
        </div>
      </div>
    </div>
  )
}

const query = graphql`
  query AuthFormQuery {
    allContentfulContentGroup(filter: {readableId: {eq: "auth-form"}}) {
      nodes {
        ...ContentGroupFragment
      }   
    }
  }
`
const translationsKeys = {
  notMemberYet: 'notMemberYet',
  createAccount: 'createAccount',
}

export default FormContainer
