import React, { useLayoutEffect, useState } from 'react'
import { useUserContext } from '../contexts/User/userContext'
import { loginPath } from './Login/Login'
import { tokenSignin } from './LayerComponents/UserIdentificationLayer'
import { getUserStateLink } from '../utils/helpers'
import { navigate } from 'gatsby'

const PrivateRoute = ({ children, location }) => {
  const { user } = useUserContext()
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  useLayoutEffect(() => {
    console.log(
      `PrivateRoute useEffect isAuth=${user.isAuth} location=`,
      location
    )

    if (user.isAuth === undefined) {
      // Authentication state is not known yet
      // This happens on fresh page loads when Firebase Auth has to initialize
      // noinspection UnnecessaryReturnStatementJS
      return
    } else if (!user.isAuth && location.pathname !== loginPath) {
      // Here we know we are not logged in and also not on the login page
      // Url-encode the original destination URL and navigate to the login screen
      const redirectStr = encodeURIComponent(
        `${location.pathname}${location.search}`
      )

      tokenSignin(`${loginPath}?r=${redirectStr}`)
    } else {
      if (location.pathname === '/mycare') {
        const startLink = getUserStateLink(user.state)
        if (startLink !== '/mycare') {
          return navigate(startLink)
        }
      }
      setIsLoggedIn(true)
    }
  }, [user.isAuth, location, user.state])

  return isLoggedIn ? <>{children}</> : null
}

export default PrivateRoute
