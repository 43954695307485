import React, { useState, useEffect } from 'react'
import { Form } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import { navigate, Link, graphql, useStaticQuery } from 'gatsby'
import { parse } from 'query-string'
import Input from '../Input'
import Button from '../common/Button'
import Error from '../common/Error'
import FormContainer from './FormContainer'
import loginValidator from '../../validation/loginValidator'
import Loading from '../Program/Loading'
import { NoIndex } from '../NoIndex'
import { useUserContext } from '../../contexts/User/userContext'
import { useGoodpatherCheck } from '../../utils/customHooks'
import { StringParam, useQueryParam } from 'use-query-params'
import { getUserStateLink } from '../../utils/helpers'
import { useLocalizedContent } from '../../utils/localization/useLocalizedContent'

// Constant for use in other places in the code. Wanted it here so we're more likely to update it if we change this page.
export const loginPath = '/login'

export const firebaseToFinalFormErrorTransformer = (error, translations = {}) => {
  switch (error.code) {
    case 'auth/too-many-requests':
      return { email: translations[translationsKeys.tooManyAttemptsError] || 'Too many attempts. Please try again later.' }
    case 'auth/user-not-found':
    case 'auth/wrong-password':
      return { email: translations[translationsKeys.wrongCredentialsError] || 'Incorrect email or password.' }
    default:
      return { [FORM_ERROR]: translations[translationsKeys.tryAgain] || 'Please try again.' }
  }
}

const LoginForm = ({ location, translations }) => {
  const { login } = useUserContext()
  const search = location?.search ? parse(location?.search) : {}
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useUserContext()

  const onSubmit = async (data) => {
    try {
      console.log(`search.r=${search.r}`)
      setIsLoading(true)
      await login(data.email, data.password, search.r)
      console.log(`Login.onSubmit login completed`)
    } catch (e) {
      console.log(`Login.onSubmit error`, e)
      setIsLoading(false)
      return firebaseToFinalFormErrorTransformer(e, translations)
    }
  }

  useEffect(() => {
    if (user?.hasAuth) {
      setIsLoading(false)
    }
  }, [user])

  return (
    <>
      <NoIndex />
      <Form onSubmit={onSubmit} validate={loginValidator(translations)}>
        {({ handleSubmit, submitErrors, submitError }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col text-left">
                <div className="mt-6">
                  <Input
                    type="email"
                    label={translations[translationsKeys.emailLabel] || "Email"}
                    name="email"
                    placeholder={translations[translationsKeys.emailPlaceholder] || "Email address"}
                  />
                </div>
                <div className="mt-6">
                  <Input
                    type="password"
                    label={translations[translationsKeys.passwordLabel] || "Password"}
                    name="password"
                    placeholder={translations[translationsKeys.passwordLabel] || "Password"}
                    passwordField={true}
                    passwordStrength={false}
                  />
                </div>
                <div className="mt-6">
                  <Button
                    isLoading={isLoading}
                    id="login-button"
                    isBlock
                    disabled={isLoading}
                    type="submit"
                    className="btn btn-primary"
                  >
                    {translations[translationsKeys.loginButton] || "Log in"}
                  </Button>
                </div>
                <div className="mt-6">
                  <p className="leading-tight text-md">
                    {translations[translationsKeys.forgotPassword] || "Forgot your password?"}&nbsp;
                    <Link to="/forgot-password">{translations[translationsKeys.resetPassword] || "Reset password."}</Link>
                  </p>
                </div>
                <div className="sticky w-full">
                  {((submitErrors && submitErrors.length > 0) ||
                    submitError) && (
                      <div className="mt-4 mb-2">
                        <Error
                          messages={submitError ? [submitError] : submitErrors}
                        />
                      </div>
                    )}
                </div>
              </div>
            </form>
          )
        }}
      </Form>
    </>
  )
}

const Login = ({ location }) => {
  const data = useStaticQuery(query)
  const { translations } = useLocalizedContent(data)

  const [redirectUrl] = useQueryParam('r', StringParam)
  const { user: { email, isAuth, state } = {} } = useUserContext()
  const [isReady, setIsReady] = useState(false)
  useGoodpatherCheck(email)

  useEffect(() => {
    const doIt = async () => {
      // Make sure we only navigate if there's no redirect url...
      if (isAuth && (redirectUrl === '/mycare' || !redirectUrl)) {
        const startLink = getUserStateLink(state)
        return navigate(startLink)
      }
      setIsReady(true)
    }
    doIt()
  }, [isAuth, redirectUrl, state])

  return !isReady ? (
    <div className="pb-40">
      <Loading onlyImage />
    </div>
  ) : (
    <FormContainer
      title={translations[translationsKeys.title] || "Sign in to your account"}
      Component={() => <LoginForm translations={translations} location={location} />}
      location={location}
    />
  )
}

const query = graphql`
  query LoginQuery {
    allContentfulContentGroup(filter: {readableId: {eq: "login"}}) {
      nodes {
        ...ContentGroupFragment
      }   
    }
  }
`
const translationsKeys = {
  title: 'title',
  emailLabel: 'emailLabel',
  emailPlaceholder: 'emailPlaceholder',
  passwordLabel: 'passwordLabel',
  loginButton: 'loginButton',
  forgotPassword: 'forgotPassword',
  tooManyAttemptsError: 'tooManyAttemptsError',
  wrongCredentialsError: 'wrongCredentialsError',
  tryAgain: 'tryAgain',
}

export default Login
