const validator = (errorMessages, values) => {
  const errors = {}
  Object.entries(errorMessages).forEach(([key, validators]) => {
    validators.forEach(({ message, validate }) => {
      if (errors[key]) {
        return
      }
      if (!validate(values[key], values)) {
        errors[key] = message
      }
    })
  })

  console.log(`validator errors`, errors)

  return errors
}

export default validator
