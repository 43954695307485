import React from 'react'
import { useUserContext } from '../contexts/User/userContext'
import PrivateRoute from '../components/PrivateRoute'
import { NoIndex } from '../components/NoIndex'
import { useUserAuthToken } from 'goodpath-common'

const UserContextDump = () => {
  const userContextState = useUserContext()
  const { token } = useUserAuthToken(useUserContext)

  return (
    <div className="flex flex-row align-center flex-grow mx-20">
      <div className="my-20 mx-auto break-all">
        <pre>{JSON.stringify({ ...userContextState, token }, null, 2)}</pre>
      </div>
    </div>
  )
}

const DebugUserContextDump = ({ location }) => (
  <PrivateRoute location={location}>
    <NoIndex />
    <UserContextDump />
  </PrivateRoute>
)

export default DebugUserContextDump
