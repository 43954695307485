import IsEmail from 'validator/lib/isEmail'
import validator from './validator'

const translationsKeys = {
  emailRequiredError: 'emailRequiredError',
  emailError: 'emailError',
  passwordRequired: 'passwordRequired',
  passwordLengthError: 'passwordLengthError',
  passwordFormatError: 'passwordFormatError'
}

export const emailValidators = (translations) => [
  {
    message: translations[translationsKeys.emailRequiredError] || 'Email is required',
    validate: (value) => {
      return value && value.trim().length !== 0
    }
  },
  {
    message: translations[translationsKeys.emailError] || 'Provide a valid email',
    validate: (value) => {
      return IsEmail(value)
    }
  }
]

const hasWhiteSpaceAtStartOrEnd = (value) => {
  return value.trim().length !== value.length
}

export const passwordBaseValidator = (translations) => [
  {
    message: translations[translationsKeys.passwordRequired] || 'Password is required',
    validate: (value) => {
      return value && value.trim().length !== 0
    }
  }
]

export const passwordValidators = (translations) => [
  ...passwordBaseValidator(translations),
  ...[
    {
      message: translations[translationsKeys.passwordLengthError] || 'Password must be at least 8 characters long',
      validate: (value) => {
        return value.length >= 8
      }
    },
    {
      message: translations[translationsKeys.passwordFormatError] || 'Password cannot start or end with a space character',
      validate: (value) => {
        return value && !hasWhiteSpaceAtStartOrEnd(value)
      }
    }
  ]
]

const loginErrorMessages = (translations) => {
  return {
    email: emailValidators(translations),
    password: passwordBaseValidator(translations)
  }
}

const loginValidator = (translations = {}) => (values) => {
  return validator(loginErrorMessages(translations), values)
}

const passwordErrorMessages = (translations) => {
  return {
    password: passwordValidators(translations)
  }
}

export const passwordValidator = (translations = {}) => (values) => {
  return validator(passwordErrorMessages(translations), values)
}

export default loginValidator
