import { navigate } from 'gatsby'
import React, { useEffect, useMemo, useState } from 'react'
import { useUserContext } from '../../contexts/User/userContext'
import Loading from '../Program/Loading'
import app from '../../utils/firebase'
import { getAuth, signInWithCustomToken } from 'firebase/auth'

export const AuthPage = {
  LOGIN: 'login',
  SIGNUP: 'signup'
}

export default function UserIdentificationLayer({
  children,
  location,
  checkOnlyLoggedIn,
  authPageIfNotAuthed = AuthPage.LOGIN
}) {
  const testMode = process.env.GATSBY_CYPRESS === 'true'
  const { user, userInitialized } = useUserContext() || {}
  const [loaded, setLoaded] = useState(testMode)

  const redirectUrl = useMemo(() => location?.pathname, [location?.pathname])

  useEffect(() => {
    if (testMode) {
      setLoaded(true)
      return
    }

    if (userInitialized) {
      if (user.state?.type === 'enrolled' && !checkOnlyLoggedIn) {
        if (!user.state?.program?.approvedAt) {
          return navigate('/app-download')
        }
        return navigate('/mycare')
      }

      if (user.state?.type === 'inactive' && !checkOnlyLoggedIn) {
        return navigate('/welcome-back')
      }

      if (!user.userId || !user.email || !user.firstName || !user.isAuth) {
        const signupOrLogin =
          authPageIfNotAuthed === AuthPage.SIGNUP ? '/signup' : '/login'
        tokenSignin(`${signupOrLogin}?r=${encodeURIComponent(redirectUrl)}`)
      }
      setLoaded(true)
    }
  }, [
    authPageIfNotAuthed,
    checkOnlyLoggedIn,
    redirectUrl,
    testMode,
    user,
    userInitialized
  ])

  return <>{loaded ? children : <Loading />}</>
}

export async function tokenSignin(fallbackUrl) {
  const auth = getAuth(app)
  const token =
    typeof window !== `undefined` && window.sessionStorage.getItem('c_token')
  if (token) {
    return signInWithCustomToken(auth, token).catch((e) => {
      console.log(e)
      typeof window !== `undefined` &&
        window.sessionStorage.setItem('c_token', '')
      navigate(fallbackUrl)
    })
  } else {
    return navigate(fallbackUrl)
  }
}
